import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import DashboardService from "../../../api/services/dashboard";
import { toast } from "react-toastify";
import AlertsTimeBarChart from "./components/AlertsTimeBarChart";
import { Divider, Typography, useTheme } from "@mui/material";
import MetricCard from "../dashboard/MetricCard";
import {
  faCheckCircle,
  faExclamationTriangle,
  faKipSign,
  faListSquares,
} from "@fortawesome/free-solid-svg-icons";
import { computeAverageAlertsPerDay } from "./functions/GenerateData";

const useStyles = makeStyles((theme) => ({
  column: {
    gap: "1rem",
    width: "100%",
    height: "100%",
    display: "flex",
    padding: "3rem",
    flexDirection: "column",
  },
  row: {
    gap: "1rem",
    display: "flex",
    flexDirection: "row",
  },
  dashboardCard: {
    gap: "1rem",
    display: "flex",
    borderRadius: "10px",
    flexDirection: "row",
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#f5f5f5",
    },
    width: "100%",
    minHeight: "150px",
    maxHeight: "600px",
  },
  title: {
    paddingLeft: "1rem",
  },

  errorChartSection: {
    gap: "1rem",
    width: "55%",
    padding: "0.6rem",
    display: "flex",
    flexDirection: "column",
  },
  generalInfoSection: {
    gap: "1rem",
    width: "45%",
    display: "flex",
    padding: "0.6rem",
    flexDirection: "column",
  },
}));

const AlertDashboardSection = () => {
  let timeoutId;
  const theme = useTheme();
  const classes = useStyles();
  const [alerts, setAlerts] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const fetchData = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      const dashboardService = new DashboardService();
      dashboardService
        .getLatestAlerts()
        .then((response) => {
          setAlerts(response.data["results"]);
          setTotalItems(response.data["count"]);
        })
        .catch((error) => {
          console.error("Error fetching alerts", error);
          toast.error("Erro na busca de alertas.");
        });
    }, 500);
  };

  useEffect(() => {
    fetchData();
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.dashboardCard}>
      <div className={classes.errorChartSection}>
        <Typography className={classes.title} variant="h6">
          Alertas
        </Typography>
        <AlertsTimeBarChart alerts={alerts} />
      </div>
      <Divider orientation="vertical" flexItem />
      <div className={classes.generalInfoSection}>
        <div className={classes.row}>
          <MetricCard
            icon={faExclamationTriangle}
            title="Novos alertas"
            color="#cf0000"
            value={
              alerts?.filter((alert) => !alert.read_status).length
            }
            message={"Alertas que ainda não foram lidos"}
          />
          <MetricCard
            icon={faKipSign}
            title="Alertas por dia"
            value={computeAverageAlertsPerDay(alerts)}
            color={theme.palette.primary.main}
            message={"Média de alertas por dia na semana"}
          />
        </div>
        <div className={classes.row}>
          <MetricCard
            title="Processados"
            icon={faCheckCircle}
            color={"#00a152"}
            value={
              alerts?.filter((alert) => alert.criteria === "not-a-problem")
                .length
            }
            message={
              "Alertas que foram marcados como não problemas ou ignorados"
            }
          />
          <MetricCard
            icon={faListSquares}
            title="Semana"
            value={totalItems}
            message={"Total de alertas na semana"}
          />
        </div>
      </div>
    </div>
  );
};

export default AlertDashboardSection;
