export const generateAlertsTimeBarChartData = (alerts) => {
  /// <summary>
  /// For each two hours of the past 7 days, count how many alerts were created
  /// </summary>
  /// <param name="alerts">List of alerts</param>
  /// <returns>Array of objects with date and value</returns>

  const data = [];
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;
  const twoHours = 1000 * 60 * 60 * 2;
  const sevenDaysAgo = new Date(now - oneDay * 7);
  // Set the time to midnight
  sevenDaysAgo.setHours(0, 0, 0, 0);

  for (let i = sevenDaysAgo.getTime(); i < now.getTime(); i += twoHours) {
    const date = new Date(i);
    const nextDate = new Date(i + twoHours);
    const count = alerts.filter((alert) => {
      const alertDate = new Date(alert.created_at);
      return alertDate >= date && alertDate < nextDate;
    }).length;

    const formattedDate =
      date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
      }) +
      " " +
      date.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
      });
    data.push({ date: formattedDate, alerts: count });
  }

  return data;
};

export const computeAverageAlertsPerDay = (alerts) => {
  /// <summary>
  /// Compute the average number of alerts per day
  /// </summary>
  /// <param name="alerts">List of alerts</param>
  /// <returns>Average number of alerts per day</returns>

  return alerts.length / 7;
};
